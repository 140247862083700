var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "h-screen" },
      [
        _c(
          "vs-row",
          { staticClass: "w-full absolute", staticStyle: { top: "50%" } },
          [
            _c(
              "vs-col",
              { staticClass: "flex w-full items-center justify-center" },
              [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("parabens-questionario-concluido-com-sucesso")
                    )
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.questionnaire.view_results
          ? _c(
              "vs-row",
              { staticClass: "w-full absolute", staticStyle: { top: "60%" } },
              [
                _c(
                  "vs-col",
                  { staticClass: "flex w-full items-center justify-center" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "footer-button",
                        attrs: { type: "filled" },
                        on: { click: _vm.showAnswers },
                      },
                      [_vm._v(_vm._s(_vm.$t("visualizar-respostas")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }