
<template>
  <div>
    <div class="h-screen">
      <vs-row class="w-full absolute" style="top: 50%">
        <vs-col class="flex w-full items-center justify-center">
          <h2>{{ $t('parabens-questionario-concluido-com-sucesso') }}</h2>
        </vs-col>
      </vs-row>

      <vs-row class="w-full absolute" style="top: 60%" v-if="questionnaire.view_results">
        <vs-col class="flex w-full items-center justify-center">
          <vs-button type="filled" class="footer-button" @click="showAnswers">{{ $t('visualizar-respostas') }}</vs-button>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'

export default {
  components: {},
  props: {
    short_url_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    service: null,
    questionnaire: null,
  }),
  computed: {},
  watch: {},
  methods: {
    getContentQuestionnaireData() {
      this.$vs.loading()
      this.service.findByShortUrlId(this.short_url_id).then((response) => {
        this.questionnaire = response
        this.$vs.loading.close()
      })
    },
    showAnswers() {
      this.$router.push(`/form/${this.short_url_id}/results`)
    },
  },
  beforeMount() {
    this.service = ContentQuestionnaireService.build(this.$vs)
    this.getContentQuestionnaireData()
  },
}
</script>

<style>
.instruction {
  border: 2px solid white;
  border-radius: 5px;
}
</style>
